*,
::after,
::before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}

body,
html {
	width: 100%;
	height: 100%;
	overflow: hidden
}

html {
	font-size: 16px;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body {
	margin: 0;
	background-color: #000;
	background-position: center;
	background-size: cover;
	font-family: 'Space Grotesk', sans-serif;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: left
}

a {
	background-color: transparent;
	color: inherit;
	text-decoration: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

a:active {
	outline: 0
}

img {
	border-style: none
}

h1, h2, h3, h4, h5, h6, p, ul {
	margin: 0
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700
}

ul {
	padding: 0;
	list-style: none
}

.link {
	position: relative;
	padding: .25em 0;
	white-space: nowrap;
	cursor: none;
}

.link::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	-webkit-transform-origin: 100% 0;
	-ms-transform-origin: 100% 0;
	transform-origin: 100% 0;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: -webkit-transform .25s cubic-bezier(.25, 1, .25, 1);
	transition: -webkit-transform .25s cubic-bezier(.25, 1, .25, 1);
	-o-transition: transform .25s cubic-bezier(.25, 1, .25, 1);
	transition: transform .25s cubic-bezier(.25, 1, .25, 1);
	transition: transform .25s cubic-bezier(.25, 1, .25, 1), -webkit-transform .25s cubic-bezier(.25, 1, .25, 1)
}

.link:active::before,
.link:hover::before,
.activeLink::before {
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1)
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	background-color: #000;
	-webkit-transition: opacity 2s;
	-o-transition: opacity 2s;
	transition: opacity 2s
}

.site {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	padding-right: 50%;
	margin-right: 50%;
	width: 150%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: visible;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch
}

.container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-line-pack: justify;
	align-content: space-between;
	padding: 10vw;
	min-height: 100%
}

.bottom {
	width: 100%;
}

.header {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.header {
	-ms-flex-item-align: start;
	align-self: flex-start;
	margin-bottom: 10vw
}

.header__heading {
	font-size: 1rem
}

.header__heading>span {
	display: block
}

.header__title {
	font-weight: 400
}

.bottom {
	-ms-flex-item-align: end;
	align-self: flex-end
}

.main {
	margin-bottom: 10vw
}

.main__description {
	max-width: 32em;
	font-size: 1rem;
}

.skills-container {
	max-width: 32em;
}

.project-description {
	max-width: 32em;
	opacity: 0.7;
	font-size: 0.9rem;
	margin-bottom: 5px;
}

.text-shadow {
	text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.footer__heading {
	font-size: 1rem
}

.footer__connect>li {
	display: inline-block;
	vertical-align: top;
	margin-right: .5em
}

.portrait {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%
}

.portrait__inner {
	position: absolute;
	top: 0;
	left: 50%;
	height: 105%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.portrait__canvas {
	display: block;
	margin: 5% 0 -5%
}

.cursor {
	display: none;
	position: fixed;
	z-index: 9999;
	opacity: 0;
	-webkit-transition: opacity .25s;
	-o-transition: opacity .25s;
	transition: opacity .25s;
	pointer-events: none;
	mix-blend-mode: exclusion
}

.cursor__inner {
	position: absolute;
	left: -4px;
	top: -4px;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	background-color: currentColor;
	-webkit-transition: -webkit-transform .25s cubic-bezier(.25, 1, .25, 1);
	transition: -webkit-transform .25s cubic-bezier(.25, 1, .25, 1);
	-o-transition: transform .25s cubic-bezier(.25, 1, .25, 1);
	transition: transform .25s cubic-bezier(.25, 1, .25, 1);
	transition: transform .25s cubic-bezier(.25, 1, .25, 1), -webkit-transform .25s cubic-bezier(.25, 1, .25, 1)
}

.cursor--small {
	left: 0;
	top: 0;
	width: 0;
	height: 0
}

.cursor--big {
	left: -20px;
	top: -20px;
	border: 2px solid currentColor;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	-webkit-transition-delay: 125ms;
	-o-transition-delay: 125ms;
	transition-delay: 125ms
}

.js-animin {
	opacity: 0;
	-webkit-transform: translateY(40px) skewY(-3deg);
	-ms-transform: translateY(40px) skewY(-3deg);
	transform: translateY(40px) skewY(-3deg);
	-webkit-transition: opacity 1s, -webkit-transform 1s cubic-bezier(.25, 1, .25, 1);
	transition: opacity 1s, -webkit-transform 1s cubic-bezier(.25, 1, .25, 1);
	-o-transition: opacity 1s, transform 1s cubic-bezier(.25, 1, .25, 1);
	transition: opacity 1s, transform 1s cubic-bezier(.25, 1, .25, 1);
	transition: opacity 1s, transform 1s cubic-bezier(.25, 1, .25, 1), -webkit-transform 1s cubic-bezier(.25, 1, .25, 1)
}

@media screen and (min-width:720px) {
	html {
			font-size: 18px
	}
	.container {
			padding: 4rem
	}
	.header,
	.main {
			margin-bottom: 4rem
	}
}

@media screen and (min-width:1280px) {
	html {
			font-size: 20px
	}
}

@media screen and (min-width:1920px) {
	html {
			font-size: 24px
	}
}

@media screen and (-webkit-min-device-pixel-ratio:2),
screen and (-o-min-device-pixel-ratio:2/1),
screen and (min-resolution:2dppx) {
	html {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased
	}
}

@media screen and (pointer:fine) {
	html {
			cursor: none
	}
	.cursor {
			display: block
	}
}

.contactsUl li{
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.linkIcon{
	width: 25px;
	height: 25px;
	margin-right: 15px;
}

.bullet{
	list-style: square outside none !important;
}

select {
  padding: 10px;
	outline: none;
  border: none;
  font-size: 16px;
  background-color: transparent;
  color: #fff;
	font-family: 'Space Grotesk', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: right;
}

select:hover,
select:focus {
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}
